import React from 'react';
import { TableData } from 'utils/customData';
import format from 'date-fns/esm/format';

import { Icon } from '@grafana/ui';

import '../styles/styles.scss';
import { SimpleOptions } from 'types';

interface Props {
  row: TableData;
  options: SimpleOptions;
}

export const LogRow: React.FC<Props> = (props) => {
  const { row, options } = props;
  const [rowIsOpen, setRowIsOpen] = React.useState<boolean>(false);

  const renderDetail = () => {
    return (
      <tr className={row.level}>
        <td colSpan={3}>
          <div className="raw-data">
            {row.details.map((item) => (
              <span key={item.label} className="raw-data__item">
                {item.label} : <strong>{item.value?.toString()}</strong>
              </span>
            ))}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <React.Fragment key={row.id}>
      <tr className={row.level}>
        <td className="toggle">
          <button className={rowIsOpen ? 'active' : 'inactive'} onClick={() => setRowIsOpen(!rowIsOpen)}>
            {rowIsOpen ? (
              <Icon name="angle-down" size="lg" type="default" />
            ) : (
              <Icon name="angle-right" size="lg" type="default" />
            )}
          </button>
        </td>
        <td className="timestamp">{format(new Date(row.timestamp.toString()), 'yyyy-MM-dd HH:mm:ss')}</td>

        <td className="message">
          {options.fieldTag !== '' && <span className="tag">{row.tag}</span>} {row.message}
        </td>
      </tr>
      {rowIsOpen && renderDetail()}
    </React.Fragment>
  );
};
