import { PanelData } from '@grafana/data';
import { SimpleOptions } from 'types';

export interface TableData {
  rowId: number;
  id: string;
  timestamp: string;
  message: string;
  level: string;
  offset: number;
  tag: string;
  details: RowData[];
}

export interface RowData {
  label: string;
  value: any;
}

export const findNewData = (tableData: TableData[], data: PanelData, options: SimpleOptions) => {
  if (data.series.length > 0) {
    const tData: TableData[] = [];
    const headings = getHeadings(data);

    for (let rowId = 0; rowId < getRowCount(data); rowId += 1) {
      const id = findFieldInData(rowId, '_id', headings, data);
      const exists = tableData.find((row) => row.id === id);
      if (!exists) {
        tData.push(buildRowData(data, rowId, id, options));
      }
    }
    return tData;
  } else {
    return [];
  }
};

const getHeadings = (data: PanelData) => {
  return data.series[0].fields.map((field) => field.name);
};

const getRowCount = (data: PanelData) => {
  return data.series[0].fields[0].values.toArray().length;
};

const findFieldInData = (rowId: number, field: string, headings: string[], data: PanelData) => {
  const fieldId = headings.indexOf(field);
  return data.series[0].fields[fieldId].values.toArray()[rowId];
};

const findFieldInDetail = (details: RowData[], fieldName: string) => {
  return details.find((item) => item.label === fieldName)?.value;
};

const buildRowData = (data: PanelData, rowId: number, id: string, options: SimpleOptions) => {
  const details = detailView(data, rowId);
  return {
    rowId,
    id: id,
    timestamp: findFieldInDetail(details, options.fieldTimestamp),
    message: findFieldInDetail(details, options.fieldMessage),
    level: findFieldInDetail(details, options.fieldLevel),
    offset: findFieldInDetail(details, options.fieldOffset),
    tag: options.fieldTag !== '' ? findFieldInDetail(details, options.fieldTag) : '',
    details,
  };
};

const detailView = (data: PanelData, rowId: number): RowData[] => {
  const rowData: RowData[] = [];
  data.series[0].fields.map((field, fieldId) => {
    rowData.push({
      label: field.name,
      value: field.values.toArray()[rowId],
    });
  });
  return rowData;
};
