import React from 'react';
import { DateTime, PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { findNewData } from 'utils/customData';

import '../styles/styles.scss';
import { LogTable } from './LogTable';

interface Props extends PanelProps<SimpleOptions> {}

function usePrevious<T>(value: T) {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const CustomLogs: React.FC<Props> = ({ options, data, replaceVariables, timeRange }) => {
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [status, setStatus] = React.useState<string>('Initial');

  const currentVariables = options.variableNames
    .split(',')
    .map((v) => replaceVariables(v))
    .join('-');
  console.log('currentVariables > ', currentVariables);
  const { from, to } = timeRange.raw;
  const prevSettings = usePrevious<{ currentVariables: string; from: string | DateTime; to: string | DateTime }>({
    currentVariables,
    from,
    to,
  });

  React.useEffect(() => {
    if (prevSettings?.currentVariables !== currentVariables || prevSettings?.from !== from || prevSettings?.to !== to) {
      setStatus('Resetting');
      setTableData([]);
    } else if (data.state === 'Done') {
      if (data.series.length > 0) {
        console.log('checking Data ', data.series[0].fields[0].values.toArray().length);
      }
      const newData = findNewData(tableData, data, options);
      if (newData.length > 0) {
        setStatus(`Adding ${newData.length}`);
        setTableData([...tableData, ...newData]);
      } else {
        setStatus('Done');
      }
    }
  }, [prevSettings, currentVariables, from, to, data, tableData, options]);

  return (
    <div className={`rradar-logs rradar-logs--${data.state} rradar-logs--${options.order}`}>
      <div style={{ position: 'absolute', top: 10, right: 25, background: 'rgba(0,0,0,0.2)', padding: '0.5rem' }}>
        {status}
      </div>
      {tableData.length > 0 && <LogTable tableData={tableData} order={options.order} options={options} />}
    </div>
  );
};
