import React from 'react';
import { TableData } from 'utils/customData';
import ScrollToBottom from 'react-scroll-to-bottom';

import '../styles/styles.scss';
import { LogRow } from './LogRow';
import { SimpleOptions } from 'types';

interface Props {
  tableData: TableData[];
  order: string;
  options: SimpleOptions;
}

export const LogTable: React.FC<Props> = (props) => {
  const { tableData, order, options } = props;

  const needToSort = (order: string, a: TableData, b: TableData) => {
    if (order === 'asc') {
      return a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : a.offset - b.offset;
    } else {
      return b.timestamp < a.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : b.offset - a.offset;
    }
  };

  return (
    // <div className="rradar-logs__scroller--devs">
    <ScrollToBottom
      className="rradar-logs__scroller"
      mode={order === 'asc' ? 'bottom' : 'top'}
      initialScrollBehavior={'auto'}
    >
      <table className="rr-logs-table">
        <tbody>
          {tableData
            .sort((a, b) => needToSort(order, a, b))
            .map((row) => (
              <LogRow key={row.id} row={row} options={options} />
            ))}
        </tbody>
      </table>
    </ScrollToBottom>
    // </div>
  );
};
